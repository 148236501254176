import * as React from "react"
import { Seo } from "../components/seo"
import Layout from '../components/layout'
import Sidebar from '../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const DonatePage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Donate</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Every gift helps a family build a foundation</h2>
					<p>With your financial support, families can build strength, stability and independence with Habitat for Humanity by building a safe and secure place to call home.</p>
					<p>Families who partner with Habitat build their own homes alongside volunteers, pay an affordable mortgage and are grateful for your help.</p>
					<p>
						Please help us by making a donation today.
						<br />
						<a href="mailto:donate@habitatnorthwoods.org">donate@habitatnorthwoods.org</a>
						<br />
						<a href="tel:715.420.0412">715.420.0412</a>
					</p>
					<p>Your financial support, your time and your voice will help bring independence to families in need of a decent place to live.			</p>
					<p><a class="button" href="https://app.autobooks.co/pay/habitat-for-humanity-northwoods-wis" target="_blank">Donate Online</a></p>
					<div className="spacer"></div>
					<p className="d-flex">
						<StaticImage src="../images/donate-qr.png" alt="Donate QR Code" />
						<StaticImage src="../images/donate-box.png" alt="Donate Box" />
					</p>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default DonatePage

export const Head = () => (
	<Seo
        title="Donate | Habitat For Humanity Northwoods Wisconsin"
    />
)
